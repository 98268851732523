.contact-container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 4.8rem 0;
}

.contact-title {
  font-family: "century-gothic";
  font-size: 5.2rem;
  margin-bottom: 2.4rem;
  text-align: start;
}

.contact-inner-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.info-container {
  width: 40%;
}

.contact-subtitle {
  font-family: "century-gothic";
  font-size: 2.4rem;
}

.contact-text {
  margin-bottom: 1.6rem;
}

.contact-text-sm {
  margin-bottom: 0.8rem;
}

.map-container {
  width: 55%;
  height: 44rem;
}

.whatsapp-button {
  background-color: #25d366;
  padding: 1.2rem 1.6rem;
  color: #ffffff !important;
  border-bottom: #ffffff !important;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  transition: all 0.5s;
}

.whatsapp-button:hover {
  background-color: #128c7e;
}

.whatsapp-button span {
  border-bottom: 1px solid #ffffff;
}

.wa-logo {
  width: 15px;
  height: 15px;
  transform: translateY(10%);
}
