.carousel-container {
  width: 100%;
  display: flex;
  height: fit-content;
  overflow: hidden;
  position: relative;
  opacity: 0;
  transition: all 0.6s;
}

.carousel-container img {
  width: 100%;
}

.carousel-img {
  transition: all 1s;
  position: absolute;
}

.carousel-controllers {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2.4rem;
  background-color: rgba(156, 104, 84, 0.8);
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 100%;
  position: absolute;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.5s;
}

.carousel-controllers:hover {
  transform: scale(1.25) translateY(-25%);
}

.carousel-controllers div {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-controllers div h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.carousel-previous {
  left: 2%;
}

.carousel-next {
  right: 2%;
}

.dots-container {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 3.2rem;
}

.dots-dot {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  background-color: rgba(156, 104, 84, 0.6);
  border: 2px solid #ffffff;
  border-radius: 100%;
  transition: all 0.5s;
}

.dots-dot-active {
  background-color: rgba(255, 255, 255, 0.6);
  border-color: #9c685e;
  transform: scale(1.5);
}
