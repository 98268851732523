/*

----- Typography system -----
Font size system (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

----- Whitespace -----
Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

----- Colors -----
Primary: #D7D3C8
Tints: #ebe9e4 #efede9 #f3f2ef
Accent: #B48782, #9C685E

*/

@font-face {
  font-family: "Century-Gothic";
  src: local("CenturyGothic"), url("./css/fonts/century-gothic-regular.ttf");
}

@font-face {
  font-family: "Century-Gothic-Bold";
  src: local("CenturyGothicBold"), url("./css/fonts/century-gothic-bold.ttf");
}

@font-face {
  font-family: "Century-Schoolbook-Regular";
  src: local("CenturySchoolbookRegular"),
    url("./css/fonts/century-schoolbook-regular.ttf");
}

@font-face {
  font-family: "Century-Schoolbook-Bold";
  src: local("CenturySchoolbookBold"),
    url("./css/fonts/century-schoolbook-bold.ttf");
}

/* ------------------ */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: "Rubik", sans-serif;
  scroll-behavior: smooth;
}

.link:link,
.link:visited {
  display: inline-block;
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
  transition: all 0.4s;
}

.link:hover,
.link:active {
  border-bottom: 1px solid transparent;
}

.white-link:link,
.white-link:visited {
  display: inline-block;
  color: #ffffff;
  text-decoration: none;
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
  transition: all 0.4s;
}

.white-link:hover,
.white-link:active {
  border-bottom: 1px solid transparent;
}

.navigation-link {
  cursor: pointer;
}

.text {
  font-size: 1.8rem;
}

.text-small {
  font-size: 1.6rem;
}

.mg-bottom-sm {
  margin-bottom: 1.2rem;
}

.mg-bottom-md {
  margin-bottom: 2.4rem;
}

.mg-bottom-lg {
  margin-bottom: 4.8rem;
}

.gothic-bold {
  font-family: "century-gothic-bold";
}

.schoolbook-bold {
  font-family: "century-schoolbook-bold";
}

.no-border {
  border: none !important;
}
