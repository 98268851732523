.services-outer-container {
  background-color: rgba(243, 242, 239, 1);
}

.services-inner-container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 4.8rem 0;
  display: flex;
  justify-content: space-between;
}

.services-container {
  width: 30%;
}

.insurances-container {
  width: 60%;
  text-align: end;
}

.services-title {
  font-family: "century-gothic";
  font-size: 5.2rem;
  margin-bottom: 2.4rem;
  text-align: start;
}

.services-subtitle {
  font-family: "century-gothic";
  font-size: 2rem;
}

.services-text {
  font-family: "century-schoolbook-regular";
}

.insurances {
  display: flex;
  justify-content: space-between;
}

.insurance-container {
  min-height: 2.8rem;
}

.insurances-text {
  font-family: "century-gothic";
}

.insurances-title {
  font-family: "century-gothic";
  font-size: 5.2rem;
  margin-bottom: 2.4rem;
  text-align: end;
}
