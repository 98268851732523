.description-container {
	max-width: 120rem;
	margin: 0 auto;
	padding: 4.8rem 0;
	font-family: "century-schoolbook-regular";
	letter-spacing: 0.1rem;
	line-height: 1.5;
}

.description-inner-container {
	display: flex;
	gap: 3.2rem;
	justify-content: space-between;
	align-items: flex-start;
}

.description-title {
	font-family: "century-gothic";
	font-size: 5.2rem;
	margin-bottom: 2.4rem;
}

.description-text {
	text-indent: 3.2rem;
	margin-bottom: 1.6rem;
}

.description-img {
	width: 50%;
}
