.space-container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 4.8rem 0;
}

.space-title {
  font-family: "century-gothic";
  font-size: 5.2rem;
  margin-bottom: 2.4rem;
  text-align: end;
}

.space-images-container {
  display: flex;
  flex-wrap: wrap;
}

.container-one-alt {
  flex-direction: column-reverse;
}

.space-photo {
  width: 50%;
  opacity: 0;
  transition: all 0.6s;
}

.space-photo-alt {
  width: 100%;
  opacity: 0;
  transition: all 0.6s;
}

.last-photo {
  flex-grow: 1;
}

.photo-oneA {
  z-index: -1;
}

.space-text-container {
  padding: 0 3.6rem;
  font-family: "century-schoolbook-regular";
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.space-text {
  text-align: end;
  margin-bottom: 0.6rem;
}
