.header-container {
  position: fixed;
  width: 100%;
  background-color: rgba(180, 135, 130, 0.9);
  opacity: 0;
  transition: all 0.5s;
  z-index: 20;
}

.header {
  padding: 0rem 6.2rem;
  font-family: "century-gothic";
  font-size: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title {
  font-size: 4.4rem;
}

.buttons-container {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}

.navigation-button {
  background-color: transparent;
  border: none;
  font-family: "century-gothic";
  font-size: 2.4rem;
  cursor: pointer;
}

.burger-button {
  color: #ffffff;
  margin-bottom: 2.4rem;
}

/* ----- */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 25px;
  height: 21px;
  right: 3.6rem;
  top: 17px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 50px;
  width: 50px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffffff;
  height: 25px !important;
  width: 5px !important;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #9c685e;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
