/* 1300px 81em */
@media (max-width: 81em) {
  /* description section */
  .description-inner-container {
    padding: 0 4.8rem;
  }
  .description-title {
    padding: 0 4.8rem;
  }
  /* space section */
  .space-title {
    padding: 0 4.8rem;
  }
  /* professionals section */
  .professionals-container {
    margin: 0 4.8rem !important;
  }
  /* services section */
  .services-container,
  .insurances-container {
    padding: 0 4.8rem;
  }
  /* contact section */
  .contact-title,
  .info-container {
    padding: 0 4.8rem;
  }
}

/* 928px 58em */
@media (max-width: 58em) {
  /* hero section */
  .hero-container {
    padding: 2.4rem 0 !important;
  }
  .logo-img {
    width: 100%;
  }
  /* professionals section */
  .professionals-owners-container {
    flex-direction: column !important;
  }
  .owner-center {
    margin: 3.2rem auto !important;
  }
  .accordion-item-container {
    justify-content: center !important;
  }
  .accordion-item {
    width: 75% !important;
    gap: 3.2rem;
    justify-content: center !important;
  }
  /* contact section */
  .contact-inner-container {
    flex-direction: column;
    gap: 4.8rem;
  }
  .info-container {
    width: 100% !important;
  }
  .map-container {
    width: 100% !important;
  }
  /* footer section */
  .footer-inner-container {
    padding: 4.8rem !important;
  }
}

/* 704px 44em */
@media (max-width: 44em) {
  /* global */
  html {
    font-size: 50% !important;
  }
  /* services section */
  .insurance-container {
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem;
    text-align: center !important;
    min-height: 7rem !important;
  }
}

/* 544px 34em */
@media (max-width: 34em) {
  /* header section */
  .bm-burger-button {
    top: 12px !important;
  }
  /* space section */
  .dots-dot {
    width: 1.6rem !important;
    height: 1.6rem !important;
  }
  /* professionals section */
  .owner-center {
    flex-direction: column !important;
    width: 100% !important;
    align-items: center !important;
  }
  .owner-center .owners-text {
    width: 70% !important;
    text-align: center;
  }
  .professionals-profiles {
    justify-content: center !important;
  }
  .professional {
    width: 100% !important;
  }
  .accordion-item-container {
    padding: 0 !important;
  }
  .accordion-item {
    width: 90% !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .accordion-photo > .owner-center {
    width: 50% !important;
  }
  .accordion-text {
    width: 75% !important;
    text-align: center;
  }
  .accordion-center {
    margin: 0 auto;
  }
  /* services section */
  .services-inner-container {
    flex-direction: column;
  }
  .services-container {
    width: 100% !important;
    margin-bottom: 4.6rem;
  }
  .insurances-container {
    width: 100% !important;
    justify-self: flex-end !important;
    align-items: center !important;
  }
  .logo-img {
    padding: 7.6rem 0;
    width: 70% !important;
  }
}
