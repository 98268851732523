.footer-outer-container {
	background-color: #9c685e;
}

.footer-inner-container {
	max-width: 120rem;
	margin: 0 auto;
	padding: 4.8rem 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	color: #ffffff;
}

.footer-title {
	font-family: "century-gothic";
	font-size: 3.6rem;
	cursor: pointer;
}

.footer-credits {
	font-family: "century-gothic";
	font-size: 1.6rem;
}
