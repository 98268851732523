.hero {
	width: 100%;
	background-color: #f3f2ef;
	/* background-image: url("../images/foto-espaco-anda-03.webp"); */
	margin-bottom: 4.8rem;
}

.hero-container {
	max-width: 120rem;
	margin: 0 auto;
	padding: 7.6rem 0;
	display: flex;
	justify-content: center;
}

.logo-img {
	width: 60%;
}
